import url from '../apis/apiUrl'
import requests from './axiosHealper'
const kycService = {
    submitKyc: async (data) => {
        return requests.postRequest(`${url.apiUrl}/submitKyc`, data)
    },
    getKycList: async (data) => {
        return requests.getRequest(`${url.apiUrl}/getKycData/${data.id}`)
    },
    inputKyc: async (data) => {
        return requests.postRequest(`${url.apiUrl}/inputKyc`, data)
    },
    approvedKyc: async (data) => {
        return requests.postRequest(`${url.apiUrl}/approveKyc`, data)
    },
    rejectKyc: async (data) => {
        return requests.postRequest(`${url.apiUrl}/rejectKyc`, data)
    },
    getAllKycData: async (data) => {
        return requests.postRequest(`${url.apiUrl}/getAllKycData`, data)
    },
    deleteKycDocument: async (data) => {
        return requests.postRequest(`${url.apiUrl}/deleteKycDocument`, data)
    },
}
export default kycService