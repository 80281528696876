import axios from "axios";
import swal from "sweetalert";
import { apiUrl } from "../apis/apiUrl"

const axiosInstance = axios.create({
    headers: {
        "Content-Type": "application/json"
    }
})


axiosInstance.interceptors.response.use(
    response => {
        return response
    },
    function (error) {
        if (error.response.status === 444) {
           
            const refreshToken = localStorage.getItem("refreshToken");

            axios.post(`${apiUrl}/refreshToken`, { refreshToken: refreshToken}).then((resp) => {
                
                if (resp.data.status) {
                    var data = resp.data
                    localStorage.setItem("accessToken",data.data.accessToken)
                    localStorage.setItem("refreshToken",data.data.refreshToken)
                    window.location.reload();
                }
                else {
                    localStorage.setItem("accessToken","");
                    localStorage.setItem("refreshToken","");
                    window.location.reload();

                }
            })
        }
        return Promise.reject(error)
    }
)

export default axiosInstance
