import axios from 'axios'
import url from '../apis/apiUrl'
import requests from './axiosHealper'
const invoiceService = {
   
    
    generateInvoice:async function (data) {
        return requests.postRequest(`${url.apiUrl}/generateInvoice`, data)
    },
    downloadInvoice:async function (data) {
        return requests.postRequest(`${url.apiUrl}/downloadInvoice`, data)
    },
    getInvoicesListData:async function (data) {
        return requests.postRequest(`${url.apiUrl}/getInvoicesListData`, data)
    },

    getInvoices:async function (data) {
        return requests.getRequest(`${url.apiUrl}/getInvoices`)
    },
    
    
    
}

export default invoiceService