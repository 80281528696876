import url from '../apis/apiUrl'
import requests from './axiosHealper'
const feasibilityService = {
    
    fetchFeasibilities: async (data) => {
        return requests.postRequest(`${url.apiUrl}/fetchFeasibilities`, data)
    },
    createFeasibilityTemp: async (data) => {
        return requests.postRequest(`${url.apiUrl}/createFeasibilityTemp`, data)
    },
    createFeasibility: async (data) => {
        return requests.postRequest(`${url.apiUrl}/createFeasibility`, data)
    },
    deleteTwoOrMoreFeasibility: async (data) => {
        return requests.getRequest(`${url.apiUrl}/deleteTwoOrMoreFeasibility/${data.id}`)
    },
    actiononfeasibility: async (data) => {
        return requests.postRequest(`${url.apiUrl}/actiononfeasibility`,data)
    },
    dropFeasibility: async (data) => {
        return requests.getRequest(`${url.apiUrl}/dropFeasibility/${data.id}`)
    },
    getFeasibility: async (data) => {
        return requests.getRequest(`${url.apiUrl}/getFeasibility/${data.id}`)    
    },
    editFeasibility: async (data) => {
        return requests.postRequest(`${url.apiUrl}/editFeasibility`, data)
    },
    getCreateFeasibility: async (data) => {
        return requests.getRequest(`${url.apiUrl}/getcreatefeasibility/${data.opportunityId}`)
    },
    
    getTwoOrMoreFeasibility: async (data) => {
        return requests.getRequest(`${url.apiUrl}/getTwoOrMoreFeasibility/${data.opportunityId}`)
    },
    editFeasibilityTemp: async (data) => {
        return requests.postRequest(`${url.apiUrl}/editFeasibilityTemp`,data)
    },
    approvedfeasibilities:async (data) => {
        return requests.postRequest(`${url.apiUrl}/approvedfeasibilities`,data)
    },
}

export default feasibilityService