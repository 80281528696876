
import { combineReducers } from 'redux'
import auth from './auth';
import customer from './customer';
import opportunity from './opportunity';
import contactAndAdress from "./contactAndAdress"
import center from './center';
import feasibility from './feasiblity';
import kyc from './kyc';
import address from './address';
import loader from './loader';
import caf from './caf';
import contact from './contact';
import master from './master';

const rootReducer = combineReducers({
    auth,
    customer,
    opportunity,
    contactAndAdress,
    center,
    feasibility,
    kyc,
    address,
    caf,
    loader,
    contact,
    master
});

export default rootReducer;