import axios from "axios";
import axiosInstance from './interseptor'
const requests = {

    postRequest: async (url, data, headers = {}) => {
        return axiosInstance.request({
            method: "POST",
            url: url,
            data: data,
            headers: headers
        })
    },
    getRequest: async (url, headers = {}, params = {}) => {
        return axiosInstance.request({
            Method: "GET",
            url: url,
            headers: headers,
            params: params
        })
    },
    setHeaders: async (key, value) => {
        return axiosInstance.defaults.headers.common[key] = value || ""
    }
}


export default requests