
import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction } from "../action";
import swal from "sweetalert";
const login = createAsyncAction("login", "login");
const register = createAsyncAction("register", "register");
const getCurrentUser = createAsyncAction("getCurrentUser", "getCurrentUser");
const logout = createAsyncAction("logout", "logout");
const forgotPassword = createAsyncAction("forgotPassword", "forgotPassword");

const ResetPassword = createAsyncAction("ResetPassword", "ResetPassword")
const initialState = {
  accessToken: "",
  refreshToken: "",
  user: {},
  isSignningIn: false,
  loader: false
}

export const counterSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateAuthState: (state, action) => Object.assign(state, action.payload),
  },
  extraReducers: {
    // [login.pending]: (state) => {
    //   state.isSignningIn = true;
    // },
    // [login.fulfilled]: (state, { payload }) => {
    //   if (payload.status) {
    //     state.status = true;
    //     state.accessToken = payload.data.accessToken;
    //     state.refreshToken = payload.data.refreshToken;
    //     state.user = payload.data.user;
    //     state.isSignningIn = false;
    //   } else {
    //     swal("Error!", payload.err, "error")
    //     state.isSignningIn = false;
    //   }
    // },
    // [login.rejected]: (state, { payload }) => {
    // },
    [getCurrentUser.fulfilled]: (state, { payload }) => {
      state.user = payload;
    },
    [logout.fulfilled]: (state, { payload }) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.user = null
    },
  },
});

const { updateAuthState } = counterSlice.actions;

export { updateAuthState, login, getCurrentUser, register, logout, forgotPassword, ResetPassword };

export default counterSlice.reducer;