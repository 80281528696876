import React from "react"
import { Page, Text, View, Document, StyleSheet, Image, Font } from "@react-pdf/renderer"
import LatoBold from '../../font/Lato-Bold.ttf';
import awlivlogo from "../../assets/awlivlogo.png"

Font.register({ family: 'Lato Bold', src: LatoBold });

const GeneratedPDF = ({formData}) => {
   
    const styles = StyleSheet.create({
        container: {
            flexDirection: "column",
            backgroundColor: "white",
            paddingRight: 30,
            paddingLeft:30,
            paddingTop: 15,
            paddingBottom: 25,
        },
        logo: {

            // position: "absolute",
            // top: 20,
            // right: 20,
            width: 120 ,
            height : 40
          },
        section: {
          margin: 10,
          padding: 10,
          flexGrow: 1,
        },
        mainHeading:{
            textAlign:'center',
            marginTop:10,
        },
        sectionPurchaseNumber: {
            marginTop: 30,
            justifyContent: 'space-between',
            alignItems: 'center',
           
        },
        vendorName:{
            marginTop:15,
            fontSize:12,
            gap:2,
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1,
        },
        input: {
            border: '1px solid #000',
            padding: 5,
            marginBottom: 10,
            width: '100%',
        },
        table: {
            width: '100%',
            border: '1px solid #000',
            marginTop: 10,
            marginBottom: 20,
        },
        tableRow: {
            flexDirection: "row",
        },
        tableCell: {
        // width: "25%",
            borderBottomWidth: 0.5, 
            borderBottomColor: "#000", 
            padding: 5,
            border:'1px solid #000',
        },
        headingCell: {
            width:'40%',
            // backgroundColor: "#a2c4c9",
            // color:'#1c434c',
            textAlign:'center',
            fontWeight:'700',
            fontSize:12,
        },
        valueCell: {
            width:'40%',
            backgroundColor: "white",
            fontSize:12,
            textAlign:'center',
            // color:'#1c434c',
        },
        button: {
            backgroundColor: '#007BFF',
            color: '#FFF',
            padding: 10,
            borderRadius: 5,
            cursor: 'pointer',
        },
        address:{
            marginTop:30,
            gap:6,
            fontSize:12,
        },
        heading: {
            fontSize: 12,
            fontWeight: 'bold',
        },
        label: {
            marginTop:5,
            marginRight: 2,
            marginLeft:10,
            fontSize:12,
            // gap:2,
            fontWeight:'normal',
        },
      });
     
    return(
        <> 
        <Document>
            <Page size="A4" style={styles.container}>
                <View style={styles.logo}>
                    <Image source={awlivlogo} />
                </View>
                <View style={styles.section}>
                    <View style={styles.mainHeading}>
                        <Text style={{fontFamily: 'Lato Bold', fontSize: 16}}>Purchase Order</Text>
                    </View>
                    <View style={styles.sectionPurchaseNumber}>
                        <Text style={{fontSize: 12, marginLeft:310}}>PO No : {formData?.poNumber}</Text>
                        <Text style={{fontSize: 12, marginLeft:287}}>PO Date: {formData?.PODate}</Text>
                    </View>
                    
                    <View style={styles.vendorName}>
                        <Text>To</Text>
                        <Text>Vendor Name: {formData?.vendorName}</Text>
                        <Text>Vendor Address: {formData?.vendorAddress}</Text>
                    </View>

                    <View style={{marginTop:25, fontSize:12}}>
                    <Text>
                        <Text style={{fontFamily: 'Lato Bold', fontSize: 12,}}>Subject:</Text> Purchase Order of {formData?.purchaseOrderName} Service of BW {formData?.purchaseOrderBW}
                    </Text>
                    </View>

                    <View style={styles.address}>
                        <Text>
                            <Text style={{fontFamily: 'Lato Bold', fontSize: 12 }}>Installation Address</Text>: {formData?.installationAddress}
                        </Text>
                        <Text>
                            <Text style={{fontFamily: 'Lato Bold', fontSize: 12 }}>Billing Address</Text>: {formData?.billingAddress}
                        </Text>  
                    </View>
                    
                    <View style={{fontSize:12, marginTop: 20}}>
                        <Text>With reference to the above we are pleased to place the order for {formData?.orderFor} Service.</Text>
                    </View>

                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={[styles.tableCell, styles.headingCell]}>
                            <Text style={{fontFamily: 'Lato Bold', fontSize: 12}}>Products /</Text>
                            <Text style={{fontFamily: 'Lato Bold', fontSize: 12}}>Services</Text>
                            </View>
                        
                            <View style={[styles.tableCell, styles.headingCell]}>
                            <Text style={{fontFamily: 'Lato Bold', fontSize: 12}}>Annual Recurring Charges (ARC)</Text>
                            </View>
                            <View style={[styles.tableCell, styles.headingCell]}>
                            <Text style={{fontFamily: 'Lato Bold', fontSize: 12}}>One Time Charge (OTC)</Text>
                            </View>
                            <View style={[styles.tableCell, styles.headingCell]}>
                            <Text style={{fontFamily: 'Lato Bold', fontSize: 12}}>Mode</Text>
                            </View>
                        </View>

                        <View style={styles.tableRow}>
                            <View style={[styles.tableCell, styles.valueCell]}>
                                <Text>{formData?.product} </Text>
                            </View> 
                            <View style={[styles.tableCell, styles.valueCell]}>
                                <Text>Rs {formData?.arc}</Text>
                            </View> 
                            <View style={[styles.tableCell, styles.valueCell]}>
                                <Text>Rs {formData?.otc}</Text>
                            </View> 
                            <View style={[styles.tableCell, styles.valueCell]}>
                                <Text>{formData?.mode}</Text>
                            </View>          
                        </View>
                    </View>

                <View>
                <Text style={styles.heading}>Terms & Conditions:</Text>
                    <Text style={styles.label}>
                        1. {formData?.terms} to be delivered at the address mention.
                    </Text>
                    <Text style={styles.label}>
                        2. Payment Term will be {formData?.paymentTerm} Advance. Taxes as applicable.
                    </Text>
                    <Text style={styles.label}>
                        3. Initial Contract period shall be {formData?.contractPeriod} period. Service start date will commence from the date of execution of Internet Leased Line Services.
                    </Text>
                    <Text style={styles.label}>
                        4. Termination notice period shall be {formData?.terminationNotice}.
                    </Text>
                    <Text style={styles.label}>
                        5. Static IP to be issued along with the {formData?.staticIP} Services.
                    </Text>
                    <Text style={styles.label}>
                        6. Scope of Internet Backbone network availability should be {formData?.networkAvailability} per month.
                    </Text>
                    <Text style={styles.label}>
                        7. Payment will be made in the name of "{formData?.paymentName}".
                    </Text>
                    </View>
                </View>

                <View style={{marginTop:30, gap:1}}>
                    <Text style={{color:'red',fontSize:10}}>
                        Awliv Living Solutions Private Limited        
                    </Text>
                    <Text>
                        <Text style={{color:'red', fontSize:8}}>Regd.Office: </Text>
                        <Text style={{color:'black', fontSize:8}}>
                            C-28-29,Kissan Bhawan,Qutab Institutional Area,New Delhi-110016
                        </Text>      
                    </Text>
                    <Text style={{fontSize: 8}}>
                        <Text style={{fontFamily: 'Lato Bold'}}>Email: </Text>info@awliv.com | <Text style={{fontFamily: 'Lato Bold'}}>Phone: </Text>011-41103497   
                    </Text>
                    <Text style={{fontSize: 8}}>
                       <Text style={{fontFamily: 'Lato Bold'}}>CIN: </Text>U74999DL2016PTC301006    
                    </Text>
                </View>
            </Page>
        </Document>
        </>
    )
}

export default GeneratedPDF

 
