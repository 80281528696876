import React, { useEffect } from 'react';
import './App.css';
import { RouterProvider, useNavigate } from "react-router-dom";
import router, { protectedRouter } from './router';
import { useSelector, useDispatch } from 'react-redux';
import PageLoader from './components/PageLoader';
import axios from 'axios';
import { apiUrl } from './apis/apiUrl';
import { updateAuthState } from './redux/reducer/auth';
import { useState } from 'react';
import requests from "./services/axiosHealper"

function App() {
  // const { accessToken, refreshToken } = useSelector((state) => state.auth);
  const [accessToken,setAccessToken]=useState(localStorage.getItem("accessToken"))
  const [isMount, setIsMount] = useState(false)
  
  useEffect(() => {
    if (accessToken) {
      setIsMount(true)
      requests.setHeaders('Authorization',`Bearer ${accessToken}`);
    }
    else{
      setIsMount(false)
    }
  }, [accessToken])


  return (
    <>

      {
        isMount
          ? <RouterProvider router={protectedRouter} />
          :
          <RouterProvider router={router} />
      }
    </>

  );
}

export default App;
