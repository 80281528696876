
import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction } from "../action";
import swal from "sweetalert";
const initialState = {
  
    customerType: {},
    customerCategory: {},
    ospCategory: {},
    customersList: [],
    selectedCustomer: {},
    contactState:"",
    dataIntailstateContact:{},
    
    addressState:{},
    dataUpdatedAddress:{},
    // monaoj:{}

}

export const counterSlice = createSlice({
    name: "contactAndAddress",
    data:"",
    initialState,
    reducers: {
        updateAuthState: (state, action) => Object.assign(state, action.payload),
        upadateConact: (state, action) => {
            state.contactState += action.payload
          },
          upadateAddressState: (state, action) => {
            state.addressState += action.payload
          },
          dataUpdatedAddressState: (state, action) => {
            state.dataUpdatedAddress += action.payload
          },
          refreshContactHandler: (state, action) => {
            state.updateRefreshContact = action.payload
          }
    
    
    },
    extraReducers: {
    },
});

const { updateAuthState, upadateConact, upadateAddressState,dataUpdatedAddressState,refreshContactHandler } = counterSlice.actions;

export { updateAuthState, upadateConact, upadateAddressState,dataUpdatedAddressState,refreshContactHandler};

export default counterSlice.reducer;