import url from '../apis/apiUrl'
import requests from './axiosHealper'

const addressService = {
    getEditBillAddress: async (data) => {
        return requests.getRequest(`${url.apiUrl}/getEditBillAddress/${data.id}`, { accessToken: data.accessToken, refreshToken: data.refreshToken })
    },
    deleteBillingAddress: async (data) => {
        return requests.getRequest(`${url.apiUrl}/deleteBillingAddress/${data.id}`, { accessToken: data.accessToken, refreshToken: data.refreshToken })
    },
    editbilladdress: async (data) => {
        return requests.getRequest(`${url.apiUrl}/editbilladdress`,data )
    },
    
}

export default addressService