
import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction } from "../action";
import swal from "sweetalert";
const getCustomerCategory = createAsyncAction("getCustomerCategory", "getCustomerCategory");
const getCustomerType = createAsyncAction("getCustomerType", "getCustomerType");
const getCategoryById = createAsyncAction("getCategoryById", "getCategoryById");
const addCustomer = createAsyncAction("addCustomer", "addCustomer");
const getCustomersList = createAsyncAction("getCustomersList", "getCustomersList");
const editCustomer = createAsyncAction("editCustomer", "editCustomer");
const uploadBulkCustomers = createAsyncAction("uploadBulkCustomers","uploadBulkCustomers")
const initialState = {
    customerType: {},
    customerCategory: {},
    ospCategory: {},
    customersList: [],
    selectedCustomer: {}
}

export const counterSlice = createSlice({
    name: "customer",
    initialState,
    reducers: {
        updateCustomerState: (state, action) => Object.assign(state, action.payload),
    },
    extraReducers: {

        [getCustomerCategory.fulfilled]: (state, { payload }) => {
            if (payload.status) {
                state.customerCategory = payload.data.customerCategory
            }
            else {
                swal("Error!", payload.err, "error")
            }
        },

        [getCustomerType.fulfilled]: (state, { payload }) => {
            if (payload.status) {
                state.customerType = payload.data.customerType
            }
            else {
                swal("Error!", payload.err, "error")
            }
        },
        [getCategoryById.fulfilled]: (state, { payload }) => {
            if (payload.status) {
                state.ospCategory = payload.data.customerSubCategory
            }
            else {
                swal("Error!", payload.err, "error")
            }
        },
        [getCustomersList.fulfilled]: (state, { payload }) => {
            if (payload.status) {
                state.customersList = payload.data.customersList
            }
            else {
                swal("Error!", payload.err, "error")
            }
        },
    },
});

const { updateCustomerState } = counterSlice.actions;

export { updateCustomerState,uploadBulkCustomers, getCustomerType, getCustomerCategory, getCategoryById, addCustomer, getCustomersList, editCustomer };

export default counterSlice.reducer;