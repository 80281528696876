import url from '../apis/apiUrl'
import requests from './axiosHealper'
const centerService = {
    createCenter: async (data) => {
        return requests.postRequest(`${url.apiUrl}/createcenter`, data)
    },
    getCenterList: async (data) => {
        return requests.postRequest(`${url.apiUrl}/fetchcenters`, data);
    },
    deleteCenter: async (data) => {
        return requests.getRequest(`${url.apiUrl}/dropCenter/${data.id}`)
    },
    updateCenter: async (data) => {
        return requests.postRequest(`${url.apiUrl}/updateCenter`, data)
    },
    uploadBulkCenters: async (data) => {
        return requests.postRequest(`${url.apiUrl}/uploadBulkCenters`, data)
    },
}

export default centerService