import url from '../apis/apiUrl'
import requests from './axiosHealper'
const userService = {
    getadmincreate: async (data) => {
        return requests.getRequest(`${url.apiUrl}/admincreate`)
    },
    rolemanage: async (data) => {
        return requests.getRequest(`${url.apiUrl}/rolemanage`)
    },
    getpermission: async (data) => {
        return requests.getRequest(`${url.apiUrl}/getpermission`, {}, { role: data.role })
    },
    createEditRole: async (data) => {
        return requests.postRequest(`${url.apiUrl}/createEditRole`, data)
    },
    admincreate: async (data) => {
        return requests.postRequest(`${url.apiUrl}/admincreate`, data)
    },
    deleterole: async (data) => {
        return requests.postRequest(`${url.apiUrl}/deleterole`, data)
    },
    adminlist: async (data) => {
        return requests.getRequest(`${url.apiUrl}/adminlist`)
    },
    admindelete: async (data) => {
        return requests.postRequest(`${url.apiUrl}/admindelete`, data)
    },
    adminedit: async (data) => {
        return requests.postRequest(`${url.apiUrl}/adminedit`, data)
    },
    adminactivestatus: async (data) => {
        return requests.postRequest(`${url.apiUrl}/adminactivestatus`, data)
    },

}

export default userService