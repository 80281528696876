import axios from 'axios'
import url from '../apis/apiUrl'
import requests from './axiosHealper'
const invoiceService = {
   
    
    getHotoList:async function (data) {
        return requests.getRequest(`${url.apiUrl}/getHotoList`)
    },
    
    
}

export default invoiceService