
import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction } from "../action";
import swal from "sweetalert";
const submitKyc = createAsyncAction("submitKyc", "submitKyc");
const getKycList = createAsyncAction("getKycList", "getKycList");
const inputKyc = createAsyncAction("inputKyc", "inputKyc");
const approvedKyc = createAsyncAction("approvedKyc", "approvedKyc");
const rejectKyc = createAsyncAction("rejectKyc", "rejectKyc");
const getAllKycData = createAsyncAction("getAllKycData","getAllKycData");
const deleteKycDocument = createAsyncAction("deleteKycDocument","deleteKycDocument");
const initialState = {
    kycList: []
}

export const counterSlice = createSlice({
    name: "kyc",
    initialState,
    reducers: {
        updateAuthState: (state, action) => Object.assign(state, action.payload),
    },
    extraReducers: {

        [getKycList.fulfilled]: (state, { payload }) => {
            if (payload.status) {
                state.kycList = payload.data.kycList
            }
            else {
                swal("Error!", payload.err, "error")
            }
        },
    },
});

const { updateAuthState } = counterSlice.actions;

export { updateAuthState, submitKyc, getKycList, inputKyc, approvedKyc, rejectKyc,getAllKycData,deleteKycDocument };

export default counterSlice.reducer;