import React from  'react'
import {
    createBrowserRouter,
} from "react-router-dom";
import GeneratedPDF from './components/po/GeneratedPDF';
// public routes
const Register = React.lazy(()=>import("./components/authorization/Register"))
const Login = React.lazy(()=>import("./components/authorization//Login"))
const ForgotPassword = React.lazy(()=>import("./components/authorization//ForgotPassword"))
const ResetPassword = React.lazy(()=>import("./components/authorization//ResetPassword"))
const Valiadtion =React.lazy(()=>import("./components/authorization/Validation"));

// protected routes
const Dashboard = React.lazy(()=>import("./components/Dashboard"))
const TopBar = React.lazy(()=>import("./components/TopBar"))
const CreateCustomer =React.lazy(()=>import("./components/customer/CreateCustomer")); 
const Contact =React.lazy(()=>import("./components/contactPages/Contact")); 
const Address =React.lazy(()=>import("./components/address/Address")); 
const Profile =React.lazy(()=>import("./components/customer/Profile"));
const Opportunity =React.lazy(()=>import("./components/opportunity/Opportunity")); 
const ViewAddress =React.lazy(()=>import("./components/address/ViewAddress"));
const Kyc =React.lazy(()=>import("./components/kyc/Kyc"));
const CreateFeasibility =React.lazy(()=>import("./components/feasibility/CreateFeasibility"));
const EditCustomer =React.lazy(()=>import("./components/customer/EditCustomer"));
const Center =React.lazy(()=>import("./components/center/Center"));
const ListCenter =React.lazy(()=>import("./components/center/ListCenter")); ;
const CAF =React.lazy(()=>import("./components/caf/Caf"));
const CreateCircuit =React.lazy(()=>import("./components/circuite/CreateCircuit")); 
const SaveCircuit =React.lazy(()=>import("./components/circuite/SaveCircuit")); 
const ViewAndUploadCaf =React.lazy(()=>import("./components/caf/ViewAndUploadCaf"));
const ListDashboardFeasibility =React.lazy(()=>import('./components/feasibility/ListDashboardFeasibility')); ;
const GenerateCircuiteId =React.lazy(()=>import("./components/circuite/GenerateCircuiteId")); 
const AllCaf =React.lazy(()=>import( './components/caf/AllCaf'));
const AllOpportunity =React.lazy(()=>import("./components/opportunity/AllOpportunity")); 
const KycList =React.lazy(()=>import('./components/kyc/KycList'));
const User =React.lazy(()=>import('./components/user/User'));
const Role =React.lazy(()=>import('./components/user/Role')); 
const UserList =React.lazy(()=>import('./components/user/UserList'));
const DashboardListCircuite =React.lazy(()=>import( './components/circuite/DashboardListCircuite'));
const DashboardListHoto =React.lazy(()=>import('./components/hoto/DashboardListHoto'));
const Createhoto =React.lazy(()=>import("./components/hoto/Createhoto"));
const DownLoadHotoAndInvoice =React.lazy(()=>import("./components/hoto/DownLoadHotoAndInvoice"));
const ListDashboardInvoice =React.lazy(()=>import("./components/Invoice/ListDashboardInvoice")); 
const Master =React.lazy(()=>import("./components/master/Master")); 
const POList =React.lazy(()=>import('./components/po/POList'));

const router = createBrowserRouter(
    [
        {
            path: "login",
            element: <Login />,
        },
        {
            path: "forgotPassword",
            element: <ForgotPassword />,
        },
        {
            path: "ResetPassword",
            element: <ResetPassword />,
        },
        {
            path: "register",
            element: <Register />,
        },
        {
            path: "validation",
            element: <Valiadtion />,
        },
        {
            path: "*",
            element: <Login />
        },

    ]);
export const protectedRouter = createBrowserRouter([
    {
        path: "home",
        element: <><TopBar /> <Dashboard /></>,

    },
    {
        path: "master",
        element:<><TopBar/> <Master /></>,
        
    },
    
    {
        path: "contact",
        element: <><TopBar /> <Contact /></>,
    },
    {
        path: "createCustomer",
        element: <><TopBar /> <CreateCustomer /></>
    },
    {
        path: "editCustomer",
        element: <><TopBar /> <EditCustomer /></>
    },
    {
        path: "profile",
        element: <><TopBar /> <Profile /></>
    },
    {
        path: "address",
        element: <><TopBar /> <Address /></>
    },
    {
        path: "viewAddress",
        element: <><TopBar /> <ViewAddress /></>
    },
    {
        path: "Opportunity",
        element: <><TopBar /> <Opportunity /></>
    },
    {
        path: "Center",
        element: <><TopBar /> <Center /></>
    },

    {
        path: "listCircuit",
        element: <><TopBar /> <DashboardListCircuite /></>
    },
    {
        path: "listHoto",
        element: <><TopBar /> <DashboardListHoto /></>
    },
    {
        path: "listCenter",
        element: <><TopBar /> <ListCenter /></>
    },
    {
        path: "listFeasibility",
        element: <><TopBar /> <ListDashboardFeasibility /></>
    },
    {
        path: "*",
        element: <><TopBar /> <Dashboard /></>
    },
    {
        path: "createfeasibility",
        element: <><TopBar /> <CreateFeasibility /></>
    },
    {
        path: "createCircuit",
        element: <><TopBar /> <CreateCircuit /></>
    },
    {
        path: "generateHoto",
        element: <><TopBar /> <Createhoto /></>
    },
    {
        path: "downloadHoto",
        element: <><TopBar /> <DownLoadHotoAndInvoice /></>
    },

    {
        path: "saveCircuit",
        element: <><TopBar /> <SaveCircuit /></>
    },

    {
        path: "generateCircuite",
        element: <><TopBar /> <GenerateCircuiteId /></>
    },
    {
        path: "kycUpload",
        element: <><TopBar /> <Kyc /></>
    }, {
        path: "caf",
        element: <><TopBar /> <CAF /></>
    },
    {
        path: "viewCaf",
        element: <><TopBar /><ViewAndUploadCaf /></>
    },
    {
        path: "listInvoice",
        element: <><TopBar /><ListDashboardInvoice /></>
    },

    {
        path: "/allCaf",
        element: <><TopBar /><AllCaf /></>
    },
    {
        path: "/allOpportunities",
        element: <><TopBar /><AllOpportunity /></>
    },
    {
        path: "/allKyc",
        element: <><TopBar /><KycList /></>
    },
    {
        path: "/user",
        element: <><TopBar /><User /></>
    },
    {
        path: "/role",
        element: <><TopBar /><Role /></>
    },
    {
        path: "userList",
        element: <><TopBar /><UserList /></>
    },
    {
        path: "poList",
        element: <><TopBar /><POList /></>
    },
    {
        path: "/:id",
        element: <><GeneratedPDF /></>
    }
])

export default router