import url from '../apis/apiUrl'
import requests from './axiosHealper'
const opportunityService = {
    createOpportunity: async (data) => {
        return requests.postRequest(`${url.apiUrl}/createopportunity`, data)
    },
    updateOpportunity: async (data) => {
        return requests.postRequest(`${url.apiUrl}/editopportunity`, data)
    },
    getOpportunitiesList: async (data) => {
        return requests.postRequest(`${url.apiUrl}/fetchopportunities`, data);
    },
    deleteOpportunity: async (data) => {
        return requests.getRequest(`${url.apiUrl}/dropopportunity/${data.id}`)
    },
    fetchAllopportunities: async (data) => {
        return requests.postRequest(`${url.apiUrl}/fetchAllopportunities`, data)
    },
}

export default opportunityService