import { createAsyncAction } from "../action";
import { createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";

const getCafCreate = createAsyncAction("getCafCreate", "getCafCreate");
const createCaf = createAsyncAction("createCaf", "createCaf");
const getCafList = createAsyncAction("getCafList", "getCafList");
const uploadCafDocuments = createAsyncAction("uploadCafDocuments", "uploadCafDocuments");
const uploadCafStatus = createAsyncAction("uploadCafStatus", "uploadCafStatus");
const getAllCafList = createAsyncAction("getAllCafList", "getAllCafList");
const getCountOfActions = createAsyncAction("getCountOfActions", "getCountOfActions");
const initialState = {
    cafList: [],
    allCafList: [],
    counts: {}
}


export const counterSlice = createSlice({
    name: "caf",
    initialState,
    reducers: {
        updateCafState: (state, action) => Object.assign(state, action.payload),
    },
    extraReducers: {


        [getCafList.fulfilled]: (state, { payload }) => {
            if (payload.status) {
                state.cafList = payload.data.cafList;
            } else {
                swal("Error!", payload.err, "error")
            }

        },
        [getAllCafList.fulfilled]: (state, { payload }) => {
            if (payload.status) {
                state.allCafList = payload.data.cafList;
            } else {
                swal("Error!", payload.err, "error")
            }

        },
        // [getCountOfActions.fulfilled]: (state, { payload }) => {
        //     if (payload.status) {
        //         state.counts = payload.data;
        //     } else {
        //         swal("Error!", payload.err, "error")
        //     }
        // },
    },
});

const { updateCafState } = counterSlice.actions;

export { updateCafState, getCountOfActions, getCafCreate, createCaf, getCafList, uploadCafDocuments, uploadCafStatus, getAllCafList };

export default counterSlice.reducer;