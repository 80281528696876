import url from '../apis/apiUrl'
import requests from './axiosHealper'
const customerService = {
    getCustomerCategory: async (data) => {
        return requests.getRequest(`${url.apiUrl}/getcustomercategory`)
    },
    getCustomerType: async (data) => {
        return requests.getRequest(`${url.apiUrl}/getcustomertype`)
    },
    getCategoryById: async (data) => {
        return requests.getRequest(`${url.apiUrl}/getcategorybyid`)
    },
    addCustomer: async (data) => {
        return requests.postRequest(`${url.apiUrl}/createcustomer`, data);
    },
    editCustomer: async (data) => {
        return requests.postRequest(`${url.apiUrl}/editcustomer`, data);
    },
    getCustomersList: async (data) => {
        return requests.postRequest(`${url.apiUrl}/fetchcustomers`, data);
    },
    uploadBulkCustomers:async(data)=>{
        return requests.postRequest(`${url.apiUrl}/uploadBulkCustomers`, data);
    }
}

export default customerService