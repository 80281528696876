import url from '../apis/apiUrl'
import requests from './axiosHealper'

const contactServices = {
    technicalContactlist: async (data) => {
        return requests.postRequest(`${url.apiUrl}/technicalContactlist`, data)
    },
    createContact: async (data) => {
        return requests.postRequest(`${url.apiUrl}/createContact`, data)
    },
    deleteContact: async (data) => {
        return requests.getRequest(`${url.apiUrl}/deleteContact/${data.id}`)
    },
    updateContact: async (data) => {
        return requests.postRequest(`${url.apiUrl}/updateContact`, data)
    },
    getContact: async (data) => {
        return requests.getRequest(`${url.apiUrl}/getContact/${data.id}`)
    },
    getContactlistfromServer: async (data) => {
        return requests.getRequest(`${url.apiUrl}/getContact/${data.id}`)
        // return axios.get(`${url.apiUrl}/getContact/${data}`)
   },
    // getFeasibility: async (data) => {
    //     return axios.get(`${url.apiUrl}/getFeasibility/${data}`)
    // }
    
    createPo: async (data) => {
        return requests.postRequest(`${url.apiUrl}/pocreate`, data)
    },

    poList : async (data) =>{
        return requests.postRequest(`${url.apiUrl}/poList`, data)
    },
    
    editPo: async (data) =>{
        return requests.postRequest(`${url.apiUrl}/editPo`, data)
    },
  
}

export default contactServices