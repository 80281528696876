
import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction } from "../action";
import swal from "sweetalert";
const createOpportunity = createAsyncAction("createOpportunity", "createOpportunity");
const updateOpportunity = createAsyncAction("updateOpportunity", "updateOpportunity")
const getOpportunitiesList = createAsyncAction("getOpportunitiesList", "getOpportunitiesList");
const deleteOpportunity = createAsyncAction("deleteOpportunity", "deleteOpportunity");
const fetchAllopportunities = createAsyncAction("fetchAllopportunities","fetchAllopportunities");
const initialState = {
    opportunityList: []
}

export const counterSlice = createSlice({
    name: "opportunity",
    initialState,
    reducers: {
        updateAuthState: (state, action) => Object.assign(state, action.payload),
    },
    extraReducers: {

        [getOpportunitiesList.fulfilled]: (state, { payload }) => {
            if (payload.status) {
                state.opportunityList = payload.data.opportunityList
            }
            else {
                swal("Error!", payload.err, "error")
            }
        },
    },
});

const { updateAuthState } = counterSlice.actions;

export { updateAuthState, createOpportunity, fetchAllopportunities,updateOpportunity, getOpportunitiesList, deleteOpportunity };

export default counterSlice.reducer;