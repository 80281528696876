import axios from 'axios'
import url from '../apis/apiUrl'
import requests from './axiosHealper'
const circuiteService = {
    getLinksForCaf: async function (data) {
        return requests.postRequest(`${url.apiUrl}/getLinksForCaf`, data)
    },
    caflistAdminAproved: async (data) => {
        return requests.getRequest(`${url.apiUrl}/caflistAdminAproved/${data.id}`)
    },
    getCircuit: async (data) => {
        return requests.getRequest(`${url.apiUrl}/getCircuit`)
    },
    linkdetails: async (data) => {
        return requests.getRequest(`${url.apiUrl}/linkdetails/${data.id}`)
    },
    getlinklist: async (data) => {
        return requests.getRequest(`${url.apiUrl}/getlinklist/${data.id}`)
    },
    updateLink: async function (data) {
        return requests.postRequest(`${url.apiUrl}/updateLink`, data)
    },
    uploadHotoDocument: async function (data) {
        return requests.postRequest(`${url.apiUrl}/uploadHotoDocument`, data)
    },

    createHoto: async function (data) {
        return requests.postRequest(`${url.apiUrl}/createHoto`, data)
    },
    getCompletedCircuit: async function (data) {
        return requests.postRequest(`${url.apiUrl}/getCompletedCircuit`, data)
    },
    createCircuit: async function (data) {
        return requests.postRequest(`${url.apiUrl}/createcircuit`, data)
    },
}

export default circuiteService