
import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction } from "../action";
import swal from "sweetalert";


const actiononfeasibility = createAsyncAction("actiononfeasibility", "actiononfeasibility");

const createFeasibilityTemp = createAsyncAction("createFeasibilityTemp", "createFeasibilityTemp");
const editFeasibilityTemp = createAsyncAction("editFeasibilityTemp", "editFeasibilityTemp");
const deleteTwoOrMoreFeasibility = createAsyncAction("deleteTwoOrMoreFeasibility", "deleteTwoOrMoreFeasibility");
const getTwoOrMoreFeasibility = createAsyncAction("getTwoOrMoreFeasibility", "getTwoOrMoreFeasibility");
const createFeasibility = createAsyncAction("createFeasibility", "createFeasibility");
const editFeasibility = createAsyncAction("editFeasibility", "editFeasibility");
const getFeasibility = createAsyncAction("getFeasibility", "getFeasibility");
const dropFeasibility = createAsyncAction("dropFeasibility", "dropFeasibility");
const getCreateFeasibility = createAsyncAction("getCreateFeasibility", "getCreateFeasibility");
const approvedfeasibilities = createAsyncAction("approvedfeasibilities", "approvedfeasibilities");
const fetchFeasibilities = createAsyncAction("fetchFeasibilities", "fetchFeasibilities");


const initialState = {
  customerType: {},
  customerCategory: {},
  ospCategory: {},
  customersList: [],
  selectedCustomer: {},
  opportunityIdfromListopp: {},
  feasibilityDataFromFeasinilityModal: "",
  feasibilityList: [],
  feasibilityByOpportunity: [],
  feasibilityDropdownOrInput:""
}

export const counterSlice = createSlice({
  name: "feasibility",
  initialState,
  reducers: {
    updateAuthState: (state, action) => Object.assign(state, action.payload),

    opportunityIdfromListoppHandler: (state, action) => {
      state.opportunityIdfromListopp += action.payload
    },
    feasibilityDataFromFeasinilityModalHandler: (state, action) => {
      state.feasibilityDataFromFeasinilityModal = action.payload
    },
    feasibilityDropdownOrInputHandler: (state, action) => {
      state.feasibilityDropdownOrInput = action.payload
    }


  },
  extraReducers: {
    [getFeasibility.fulfilled]: (state, { payload }) => {
      if (payload.status) {
        state.feasibilityList = payload.data.feasibility
      }
      else {
        swal("Error!", payload.err, "error")
      }
    },
    [getCreateFeasibility.fulfilled]: (state, { payload }) => {
      if (payload.status) {
        state.feasibilityByOpportunity = payload.data.feasibility
      }
      else {
        swal("Error!", payload.err, "error")
      }
    },
    // [editFeasibility.fulfilled]: (state, { payload }) => {
    //   if (payload.status) {
    //     state.feasibilityList = payload.data.feasibility
    //   }
    //   else {
    //     swal("Error!", payload.err, "error")
    //   }
    // },
  },
});

const { updateAuthState, opportunityIdfromListoppHandler,feasibilityDropdownOrInputHandler, feasibilityDataFromFeasinilityModalHandler } = counterSlice.actions;

export { updateAuthState,fetchFeasibilities, approvedfeasibilities,opportunityIdfromListoppHandler,feasibilityDropdownOrInputHandler,actiononfeasibility,editFeasibilityTemp,createFeasibilityTemp, getCreateFeasibility,createFeasibility,getTwoOrMoreFeasibility,deleteTwoOrMoreFeasibility, feasibilityDataFromFeasinilityModalHandler, dropFeasibility, getFeasibility, editFeasibility };
// export { updateAuthState, , opportunityIdfromListoppHandler, actiononfeasibility, editFeasibilityTemp, createFeasibilityTemp, getCreateFeasibility, createFeasibility, getTwoOrMoreFeasibility, deleteTwoOrMoreFeasibility, feasibilityDataFromFeasinilityModalHandler, dropFeasibility, getFeasibility, editFeasibility };

export default counterSlice.reducer;