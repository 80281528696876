
import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    loading: false,
}

export const counterSlice = createSlice({
    name: "loader",
    initialState,
    reducers: {
        updateLoaderState: (state, action) => Object.assign(state, action.payload),
    },
});

const { updateLoaderState } = counterSlice.actions;

export { updateLoaderState };

export default counterSlice.reducer;