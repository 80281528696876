

import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction } from "../action";
import swal from "sweetalert";

const createMaster= createAsyncAction("createMaster", "createMaster");
const getMaster= createAsyncAction("getMaster", "getMaster");




const initialState = {
  master: [],
    loading:false
}

export const counterSlice = createSlice({
    name: "hoto",
    initialState,
    reducers: {
        updateAuthState: (state, action) => Object.assign(state, action.payload),
    },
    extraReducers: {
        [createMaster.fulfilled]: (state, { payload }) => {
            if (payload.status) {
                state.master = payload.data
            }
            else {
                swal("Error!", payload.err, "error")
            }
        },
    },
});

const { updateAuthState } = counterSlice.actions;

export {  createMaster,updateAuthState,getMaster  };

export default counterSlice.reducer;