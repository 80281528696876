import url from '../apis/apiUrl'
import requests from './axiosHealper'
const cafService = {
    getCafCreate: async function (data) {
        return requests.getRequest(`${url.apiUrl}/getcafcreate/${data.opportunityId}/${data.type}/${data.feasibilityId}`)
    },
    createCaf: async function (data) {
        return requests.postRequest(`${url.apiUrl}/createCaf`, data)
    },
    getCafList: async function (data) {
        return requests.postRequest(`${url.apiUrl}/getCafList`, data)
    },
    getAllCafList: async function (data) {
        return requests.postRequest(`${url.apiUrl}/getAllCafList`, data)
    },
    uploadCafDocuments: async function (data) {
        return requests.postRequest(`${url.apiUrl}/uploadCafDocuments`, data)
    },
    uploadCafStatus: async function (data) {
        return requests.postRequest(`${url.apiUrl}/uploadCafStatus`, data)
    },
    getCountOfActions: async function (data) {
        return requests.getRequest(`${url.apiUrl}/getCountOfActions`)
    },
}

export default cafService
