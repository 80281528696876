
import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction } from "../action";
import swal from "sweetalert";
const createCenter= createAsyncAction("createCenter", "createCenter");
const getCenterList = createAsyncAction("getCenterList", "getCenterList");
const deleteCenter = createAsyncAction("deleteCenter", "deleteCenter");
const updateCenter = createAsyncAction("updateCenter","updateCenter");
const uploadBulkCenters  =createAsyncAction("uploadBulkCenters","uploadBulkCenters");
const initialState = {
    centerList: [],
    loading:false
}

export const counterSlice = createSlice({
    name: "center",
    initialState,
    reducers: {
        updateAuthState: (state, action) => Object.assign(state, action.payload),
    },
    extraReducers: {
        [getCenterList.pending]: (state, { payload }) => {
          state.loading = true
        },
        [getCenterList.fulfilled]: (state, { payload }) => {
            if (payload.status) {
                state.centerList = payload.data.centerList
                state.loading=false
            }
            else {
                swal("Error!", payload.err, "error")
            }
        },
    },
});

const { updateAuthState } = counterSlice.actions;

export { updateAuthState, createCenter, getCenterList, deleteCenter,updateCenter,uploadBulkCenters };

export default counterSlice.reducer;