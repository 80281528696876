
import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction } from "../action";
import swal from "sweetalert";
const getEditBillAddress = createAsyncAction("getEditBillAddress", "getEditBillAddress");
const deleteBillingAddress = createAsyncAction("deleteBillingAddress", "deleteBillingAddress");
const editbilladdress = createAsyncAction("editbilladdress", "editbilladdress");


const initialState = {
    addressList: []
}

export const counterSlice = createSlice({
    name: "address",
    initialState,
    reducers: {
        updateAuthState: (state, action) => Object.assign(state, action.payload),
    },
    extraReducers: {

        [getEditBillAddress.fulfilled]: (state, { payload }) => {
            
            if (payload.status) {
                state.addressList = payload.data
            }
            else {
                swal("Error!", payload.err, "error")
            }
        },
    },
});

const { updateAuthState } = counterSlice.actions;

export { updateAuthState, getEditBillAddress,deleteBillingAddress,editbilladdress };

export default counterSlice.reducer;