import url from '../apis/apiUrl'
import requests from './axiosHealper'

const authService = {
    login: async (data) => {
        return requests.postRequest(`${url.apiUrl}/login`, data)
    },
    register: async (data) => {
        return requests.postRequest(`${url.apiUrl}/user/register`, data)
    },
    logout: async () => {
        return { msg: "logging out" }
    },
    forgotPassword: async (data) => {
        return requests.postRequest(`${url.apiUrl}/passwordresetreq`, data);
    },
    ResetPassword: async (data) => {
        return requests.postRequest(`${url.apiUrl}/performresetpassword`, data);
    }
}

export default authService