import axios from 'axios'
import url from '../apis/apiUrl'
import requests from './axiosHealper'
const msaterServices = {
   
    
    createMaster:async function (data) {
        return requests.postRequest(`${url.apiUrl}/createMaster`, data)
    },
    getMaster:async function (data) {
        return requests.getRequest(`${url.apiUrl}/getMaster`)
    },
}

export default msaterServices