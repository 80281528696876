
import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction } from "../action";
import swal from "sweetalert";

const updateContact = createAsyncAction("updateContact", "updateContact");
const deleteContact = createAsyncAction("deleteContact", "deleteContact");
const getContact = createAsyncAction("getContact", "getContact");
const createContact = createAsyncAction("createContact", "createContact");
const technicalContactlist = createAsyncAction("technicalContactlist", "technicalContactlist");
const getContactlistfromServer = createAsyncAction("getContact", "getContact");

const createPo = createAsyncAction("createPo", "createPo");   //Po create
const poList = createAsyncAction("poList", "poList");  
const editPo = createAsyncAction("editPo", "editPo")

const initialState = {
    customerType: {},
    customerCategory: {},
    refreshContact:{} ,  
    getContactList:[]
}

export const counterSlice = createSlice({
    name: "contact",
    initialState,
    reducers: {
        updateAuthState: (state, action) => Object.assign(state, action.payload),
        
          opportunityIdfromListoppHandler: (state, action) => {
            state.opportunityIdfromListopp += action.payload
          },
          refreshContactHandler: (state, action) => {
            state.refreshContact = action.payload
          }
    
    
    },
    extraReducers: {
      
      
      
    },
});

const { updateAuthState, opportunityIdfromListoppHandler,feasibilityDataFromFeasinilityModalHandler,refreshContactHandler } = counterSlice.actions;

export { createPo, editPo, poList, updateAuthState,technicalContactlist, opportunityIdfromListoppHandler,feasibilityDataFromFeasinilityModalHandler,refreshContactHandler,updateContact,deleteContact,getContact,createContact,getContactlistfromServer};

export default counterSlice.reducer;