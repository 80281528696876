
import authService from "./authService";
import customerService from "./customerService";
import opportunityService from "./opprtunityService";
import centerService from "./centerService";
import kycService from "./kycService";
import addressService from "./addressService";
import feasibilityService from "./feasibilityService";
import contactServices from "./contactServices";
import cafService from "./cafService";
import userService from "./userService";
import circuiteService from "./circuiteService";
import invoiceService from "./invoiceService";
import hotoServices from "./hotoServices"
import msaterServices from "./msaterServices"
const service = {
    ...authService,
    ...customerService,
    ...opportunityService,
    ...centerService,
    ...kycService,
    ...addressService,
    ...feasibilityService,
    ...contactServices,
    ...cafService,
    ...userService,
    ...circuiteService,
    ...invoiceService,
...hotoServices,
...msaterServices
}

export default service